import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from "vue-router";
import axios from 'axios'
//import jwt from "jsonwebtoken"
import VueAxios from 'vue-axios'
import DatetimePicker from 'vuetify-datetime-picker'
import VueResizeText from 'vue-resize-text';
import "./plugins/vuetify-money.js";

Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(DatetimePicker);
Vue.use(VueResizeText);
//Vue.use(VueJwt, jwt);

//const {fs} = require('fs');


export const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: () => import("./pages/HomePage.vue")
    },
    {
      path: "/login",
      component: () => import("./pages/LoginPage.vue")
    },
    {
      path: "/logout",
      component: () => import("./pages/LogoutPage.vue")
    },
    {
      path: "/lagereingang",
      component: () => import("./pages/LagereingangPage.vue")
    },
    {
      path: "/shipware",
      component: () => import("./pages/ShipwarePage.vue")
    },
    {
      path: "/packliste",
      component: () => import("./pages/PacklistePage.vue")
    },
    {
      path: "/empfaenger",
      component: () => import("./pages/EmpfaengerPage.vue")
    },
    {
      path: "/absender",
      component: () => import("./pages/AbsenderPage.vue")
    },
    {
      path: "/schiffsliste",
      component: () => import("./pages/SchiffslistePage.vue")
    },
    {
      path: "/itemlisten",
      component: () => import("./pages/ItemlistePage.vue")
    },
    {
      path: "/item",
      component: () => import("./pages/ItemPage.vue")
    },
    {
      path: "/verpackung",
      component: () => import("./pages/VerpackungPage.vue")
    },
    {
      path: "/a4",
      component: () => import("./components/PrintA4Label.vue")
    },
    {
      path: "/a5",
      component: () => import("./components/PrintA5Label.vue")
    },
    {
      path: "/shipsingle",
      component: () => import("./components/ShipwareSingleEdit.vue")
    },
    {
      path: "/grid",
      component: () => import("./pages/GridLayout.vue")
    },
    {
      path: "/admin",
      component: () => import("./pages/AdminPage.vue")
    },
    {
      path: "/detailsuche",
      component: () => import("./pages/DetailPage.vue")
    },
    {
      path: "/advancedsearch",
      component: () => import("./pages/DetailPageCustomer.vue")
    }
  ]
});

new Vue({
  router,
  vuetify,
  axios,
  render: h => h(App)
}).$mount('#app')
